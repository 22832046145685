import { Link } from 'gatsby'

const LinkList = () => {
  return (
    <ul className="linkList flex left">
      <li>
        <a href="https://top-group.jp/staff">
          <span className="ttl en">TOP STAFF</span>
          <span className="imgSpan">
            <img
              src="https://top-group.jp/web/wp-content/themes/TOP/img/index/img01.png"
              width="111"
              alt="TOP STAFF"
            />
          </span>
          <span className="txt">ティーオーピー・スタッフ</span>
          <span className="link">SERVICE</span>
        </a>
      </li>
      <li>
        <Link to="vietnam">
          <span className="ttl en">TOP VIETNAM</span>
          <span className="imgSpan">
            <img
              src="https://top-group.jp/web/wp-content/themes/TOP/img/index/img02.png"
              width="111"
              alt="TOP VIETNAM"
            />
          </span>
          <span className="txt">ティーオーピー・ベトナム</span>
          <span className="link">SERVICE</span>
        </Link>
      </li>
      <li>
        <a href="https://top-group.jp/progress">
          <span className="ttl en">TOP PROGRESS</span>
          <span className="imgSpan">
            <img
              src="https://top-group.jp/web/wp-content/themes/TOP/img/index/img03.png"
              width="111"
              alt="TOP PROGRESS"
            />
          </span>
          <span className="txt">ティーオーピー・プログレス</span>
          <span className="link">SERVICE</span>
        </a>
      </li>
      <li>
        <a href="https://top-group.jp/logi">
          <span className="ttl en">TOP LOGI</span>
          <span className="imgSpan">
            <img
              src="https://top-group.jp/web/wp-content/themes/TOP/img/index/img04.png"
              width="111"
              alt="TOP LOGI"
            />
          </span>
          <span className="txt">ティーオーピー・ロジ</span>
          <span className="link">SERVICE</span>
        </a>
      </li>
    </ul>
  )
}

export default LinkList
