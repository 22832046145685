const Information = () => {
  return (
    <div className="newsBox clearfix">
      <h3 className="en">INFORMATION</h3>
      <dl className="newsDl clearfix">
        <dt>2020.06.08</dt>
        <dd>
          <a href="https://top-group.jp/news/26.html">
            ＴＯＰ ＧＲＯＵＰのＷＥＢサイトを全面リニューアル致しました。
          </a>
        </dd>
      </dl>
    </div>
  )
}

export default Information
