import LinkList from './LinkList'
import BizOrPersonal from './BizOrPersonal'
import Information from './Information'
import ScrollReveal from '@/components/atoms/ScrollReveal'

const MainSection = () => {
  return (
    <section id="main">
      <ScrollReveal>
        <LinkList />
      </ScrollReveal>
      <ScrollReveal>
        <BizOrPersonal />
      </ScrollReveal>
      <ScrollReveal>
        <Information />
      </ScrollReveal>
    </section>
  )
}

export default MainSection
