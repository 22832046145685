import MainVisual from './MainVisual'
import MainSection from './MainSection/MainSection'
import WPTemplate from '@/components/templates/WPTemplate'

const IndexPage = () => {
  return (
    <WPTemplate>
      <MainVisual />
      <MainSection />
    </WPTemplate>
  )
}

export default IndexPage
