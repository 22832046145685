import Slider from 'react-slick'
import * as style from './index.module.scss'

const MainVisual = () => {
  const setting = {
    dots: false,
    autoplay: true,
    fade: true,
    arrows: false,
    pauseOnHover: false,
    pauseOnFocus: false,
  }

  return (
    <div className="mainVisual">
      <Slider className="imgList clearfix" {...setting}>
        <li className="bg01"></li>
        <li className="bg02"></li>
        <li className="bg03"></li>
      </Slider>
      <div className={`info ${style.mvTitle}`}>
        <h2 className="title en">TOP GROUP SOLUTION</h2>
        <div className="comLink">
          <a href="https://top-group.jp/group">TOPグループのソリューション</a>
        </div>
      </div>
    </div>
  )
}

export default MainVisual
