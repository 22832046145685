const BizOrPersonal = () => {
  return (
    <ul className="listUl clearfix">
      <li>
        <a href="https://top-group.jp/company">
          <img
            src="https://top-group.jp/web/wp-content/themes/TOP/img/index/photo01.jpg"
            alt="FOR CORPORATE"
            className="pc"
          />
          <img
            src="https://top-group.jp/web/wp-content/themes/TOP/img/index/sp_photo01.jpg"
            alt="FOR CORPORATE"
            className="sp"
          />
          <div className="inner">
            <p className="top en">FOR CORPORATE</p>
            <div className="txtBox">
              <img
                src="https://top-group.jp/web/wp-content/themes/TOP/img/index/img05.png"
                width="110"
                alt="企業の担当者様へ"
              />
              <span className="text">企業のご担当者様へ</span>
            </div>
          </div>
        </a>
        <div className="ulBox">
          <ul className="linkUl clearfix">
            <li>
              <a href="https://top-group.jp/company">企業様向けサービス</a>
            </li>
            <li>
              <a href="https://top-group.jp/staff-contact">人材派遣のご相談</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <a href="https://top-group.jp/personal">
          <img
            src="https://top-group.jp/web/wp-content/themes/TOP/img/index/photo02.jpg"
            alt="FOR PERSONAL"
            className="pc"
          />
          <img
            src="https://top-group.jp/web/wp-content/themes/TOP/img/index/sp_photo02.jpg"
            alt="FOR PERSONAL"
            className="sp"
          />
          <div className="inner">
            <p className="top en">FOR PERSONAL</p>
            <div className="txtBox clearfix">
              <img
                src="https://top-group.jp/web/wp-content/themes/TOP/img/index/img06.png"
                width="110"
                alt="お仕事をお探しの方へ"
              />
              <span className="text">お仕事をお探しの方へ</span>
            </div>
          </div>
        </a>
        <div className="ulBox">
          <ul className="linkUl">
            <li>
              <a
                href="https://www.baitoru.com/op325773/"
                target="_blank"
                rel="noreferrer"
              >
                お仕事検索はこちら
              </a>
            </li>
            <li>
              <a href="https://top-group.jp/staff-entry">お仕事エントリー</a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  )
}

export default BizOrPersonal
